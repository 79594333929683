import { useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import { Button, Link } from '@/components/atoms';
import { NavBarModal } from '@/components/molecules';
import styles from './navBar.module.scss';

const NavBar = () => {
  const navbar = useSelector((state) => state.navBar);
  const storeId = useSelector((state) => state.store);
  const navbarElements = useMemo(() => {
    if (storeId !== process.env.NEXT_PUBLIC_STORE_ID) {
      return {
        dropdowns: navbar.dropdowns,
        otherElements: navbar.links || []
      };
    }
    return { otherElements: navbar.links, dropdowns: navbar.dropdowns };
  }, [storeId]);

  const [open, setOpen] = useState({
    name: '',
    info: []
  });

  const setCategories = (name, categories) => {
    setOpen({ name, info: categories });
  };
  return (
    <div
      className={`${styles.navbar} ${open.name !== '' && styles.open}`}
      onMouseLeave={() => {
        setOpen({ name: '', info: [] });
      }}
    >
      {navbarElements.dropdowns?.length > 0 &&
        navbarElements.dropdowns.map((d) => (
          <Button
            id={d.name}
            key={d.name}
            onClick={() => {
              if (open.name === d.name) {
                setOpen({ name: '', info: [] });
              } else {
                setCategories(d.name, d.categories);
              }
            }}
            style={{
              width: `${
                100 /
                (navbarElements.otherElements.length +
                  navbarElements.dropdowns.length)
              }%`
            }}
            className={`${styles.navbarItem} ${
              d.name === open.name ? styles.selected : ''
            }`}
            label={d.name}
            textColor={
              d.name === open.name
                ? 'var(--color-primary)'
                : 'var(--color-white-absolute)'
            }
          />
        ))}
      {navbarElements.otherElements?.length > 0 &&
        navbarElements.otherElements.map((l) => (
          <div
            key={l.url}
            style={{
              width: `${
                100 /
                (navbarElements.otherElements.length +
                  navbarElements.dropdowns.length)
              }%`
            }}
            className={styles.navbarLink}
          >
            <Link
              href={`/${l.url}`}
              passHref
              allWidth
            >
              <Button
                id={l.name}
                label={l.name}
                textColor={l.text_color}
                textStyle={{
                  fontSize: '0.875rem',
                  textTransform: 'capitalize'
                }}
                withOutPadding
                style={{ backgroundColor: l.background_color }}
              />
            </Link>
          </div>
        ))}
      {open?.name && <NavBarModal content={open.info} />}
    </div>
  );
};

export default NavBar;
